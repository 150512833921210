<template>
  <div id="app">
    <Navbar/>
    <SideBar v-if="isLoggedIn"/>
    <router-view/>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import router from '@/router';
  import { mapState } from 'vuex'
  import Navbar from '@/components/layout/NavbarComponent.vue'
  import SideBar from '@/components/layout/SidebarComponent.vue'

  export default {
    components: {
      Navbar,
      SideBar
    },

    computed: {
        ...mapState(['isLoggedIn'])
    },

    mounted() {
      const tokenExpirationTime = localStorage.getItem('tokenExpiration');
      const currentTime = new Date().getTime();
      const logoutRemainingTime = tokenExpirationTime - currentTime;
      setTimeout(() => {
        router.push({ name: 'logout' });
      }, logoutRemainingTime);
    }
  }
</script>

<style lang="scss">
  @import '@/assets/sass/_variables.scss';
  @import '@/assets/sass/_mixins.scss';

  #app {
    font-family: "Nunito Sans";
    font-optical-sizing: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    width: $vp100;
    height: $vp100
  }

  body {
    background: url('./assets/img/globals/bg-carbon-fiber.jpg');
    width: $vp100;
    height: $vp100
  }
</style>
