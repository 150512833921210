<template>
  <div class="home">
    <mainCarousel/>
  </div>
</template>

<script>
import mainCarousel from '@/components/layout/main/CarouselComponent.vue'

export default {
  name: 'Home',
  components: {
    mainCarousel
  },

  mounted() {
    const reloaded = localStorage.getItem('reloaded');
    const token = localStorage.getItem('token');


    if (reloaded === 'true' && !token) {
      localStorage.removeItem('reloaded');
      localStorage.removeItem('logoutReloaded')
      localStorage.removeItem('tokenExpiration')
      location.reload();
    }
  }
}
</script>

<style lang="sass">
  @import '@/assets/sass/_variables.scss'
  @import '@/assets/sass/_mixins.scss'

  * 
    color: $color-light

  .home
    height: $vp100
    
</style>