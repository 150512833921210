import { createStore } from 'vuex'
import axios from 'axios'

const store = createStore({
  state: {
    isNavbarVisible: true,
    isLoggedIn: false,
    roles: [],
    countries: [],
    regions: [],
    provinces: [],
    cities: []
  },
  getters: {
    isNavbarVisible: (state) => state.isNavbarVisible,
    isLoggedIn: (state) => state.isLoggedIn,
    allCountries: (state) => state.countries,
    allRegions: (state) => state.regions,
    allProvinces: (state) => state.provinces,
    allCities: (state) => state.cities
  },
  mutations: {
    setNavbarVisibility(state, visibility) {
      state.isNavbarVisible = visibility;
    },
    setLoginState(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },

    SET_ROLES(state, roles) {
      state.roles = roles;
    },
    ADD_ROLE(state, role) {
      state.roles.push(role);
    },
    DELETE_ROLE(state, id) {
      state.roles = state.roles.filter((role) => role.id !== id);
    },

    SET_COUNTRIES(state, countries) {
      state.countries = countries;
    },
    ADD_COUNTRY(state, country) {
      state.countries.push(country);
    },
    DELETE_COUNTRY(state, id) {
      state.countries = state.countries.filter((country) => country.id !== id);
    },

    SET_REGIONS(state, regions) {
      state.regions = regions;
    },
    ADD_REGION(state, region) {
      state.regions.push(region);
    },
    DELETE_REGION(state, { country_id, region_id }) {
      state.regions = state.regions.filter(
        (region) => !(region.country_id === country_id && region.id === region_id)
      );
    },

    SET_PROVINCES(state, provinces) {
      state.provinces = provinces;
    },
    ADD_PROVINCE(state, province) {
      state.provinces.push(province);
    },
    DELETE_PROVINCE(state, { region_id, province_id }) {
      state.provinces = state.provinces.filter(
        (province) => !(province.region_id === region_id && province.id === province_id)
      );
    },

    SET_CITIES(state, cities) {
      state.cities = cities;
    },
    ADD_CITY(state, city) {
      state.cities.push(city);
    },
    DELETE_CITY(state, { province_id, city_id }) {
      state.cities = state.cities.filter(
        (city) => !(city.province_id === province_id && city.id === city_id)
      );
    }
  },
  actions: {
    toggleNavbar({ commit }, visivility) {
      commit('setNavbarVisibility', visivility);
    },

    login({ commit }, isLoggedIn) {
      commit('setLoginState', isLoggedIn);
    },

    async fetchRoles({ commit }) {
      try {
        const response = await axios.get('https://api.fusioncar.cl/api/user/roles',
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        );

        commit('SET_ROLES', response.data);
      } catch(error) {
        console.error('Error fetching Roles', error.response?.data || error.message);
        throw new Error(error.response.data.msg);
      };
    },

    async addRole({ commit }, roleName) {
      try {
        const response = await axios.post('https://api.fusioncar.cl/api/user/newRole',
          {
            name: roleName
          },
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        );

        commit('ADD_ROLE', response.data);
      } catch(error) {
        console.error('Error adding Role', error.response?.data || error.message);
        throw new Error(error.response.data.msg);
      };
    },

    async deleteRole({ commit }, role_id) {
      try {
        await axios.delete(`https://api.fusioncar.cl/api/user/deleteRole/${role_id}`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        );

        commit('DELETE_ROLE', role_id);
      } catch(error) {
        console.error('Error deleting Role', error.response?.data || error.message);
        throw new Error(error.response.data.msg);
      };
    },

    async fetchCountries({ commit }) {
      try {
        const response = await axios.get('https://api.fusioncar.cl/api/global/countries', 
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        );

        commit('SET_COUNTRIES', response.data);
      } catch(error) {
        console.error('Error fetching Countries', error.response?.data || error.message);
      };
    },

    async addCountry({ commit }, countryName) {
      try {
        const response = await axios.post('https://api.fusioncar.cl/api/global/newCountry',
          {
            name: countryName
          }, 
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        );

        commit('ADD_COUNTRY', response.data);
      } catch(error) {
        console.error('Error adding country', error.response?.data || error.message);
        throw new Error(error.response.data.msg);
      };
    },

    async deleteCountry({ commit }, id) {
      try {
        await axios.delete(`https://api.fusioncar.cl/api/global/deleteCountryById/${id}`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        );

        commit('DELETE_COUNTRY', id);
      } catch(error) {
        console.error('Error deleting country', error.response?.data || error.message);
        throw new Error(error.response.data.msg);
      };
    },
    
    async fetchRegions({ commit }) {
      try {
        const response = await axios.get('https://api.fusioncar.cl/api/global/regions', 
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        );
        commit('SET_REGIONS', response.data);
      } catch(error) {
        console.error('Error fetching regions', error.response?.data || error.message);
      }
    },

    async addRegion({ commit }, { regionName, countryId }) {
      try {
        const response = await axios.post('https://api.fusioncar.cl/api/global/newRegion',
          { 
            name: regionName,
            country_id: countryId
          },
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        );

        commit('ADD_REGION', response.data);
      } catch(error) {
        console.error('Error adding region', error.response?.data || error.message);
        throw new Error(error.response.data.msg);
      };
    },

    async deleteRegion({ commit }, { country_id, region_id }) {
      try {
        await axios.delete(`https://api.fusioncar.cl/api/global/deleteRegionById/${country_id}/${region_id}`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        );

        commit('DELETE_REGION', { country_id, region_id });
      } catch(error) {
        console.error('Error deleting region', error.response?.data || error.message);
        throw new Error(error.response.data.msg);
      }
    },

    async fetchProvinces({ commit }) {
      try {
        const response = await axios.get('https://api.fusioncar.cl/api/global/provinces',
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        );

        commit('SET_PROVINCES', response.data);
      } catch(error) {
        console.error('Error fetching provinces', error.response?.data || error.message);
      }
    },

    async addProvince({ commit }, { provinceName, regionId }) {
      try {
        const response = await axios.post('https://api.fusioncar.cl/api/global/newProvince',
          {
            name: provinceName,
            region_id: regionId
          },
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        );

        commit('ADD_PROVINCE', response.data);
      } catch(error) { 
        console.error('Error adding province', error.response?.data || error.message);
        throw new Error(error.response.data.msg);
      };
    },

    async deleteProvince({ commit }, { region_id, province_id }) {
      try {
        await axios.delete(`https://api.fusioncar.cl/api/global/deleteProvinceById/${region_id}/${province_id}`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        );

        commit('DELETE_PROVINCE', { region_id, province_id });
      } catch(error) {
        console.error('Error deleting province', error.response?.data || error.message);
        throw new Error(error.response.data.msg);
      }
    },

    async fetchCities({ commit }) {
      try {
        const response = await axios.get('https://api.fusioncar.cl/api/global/cities',
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        );

        commit('SET_CITIES', response.data);
      } catch(error) {
        console.error('Error fetching cities', error.response?.data || error.message);
      }
    },

    async addCity({ commit }, { cityName, provinceId }) {
      try {
        const response = await axios.post('https://api.fusioncar.cl/api/global/newCity',
          {
            name: cityName,
            province_id: provinceId
          },
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        );

        commit('ADD_CITY', response.data);
      } catch(error) {
        console.error('Error adding city', error.response?.data || error.message);
        throw new Error(error.response.data.msg);
      };
    },

    async deleteCity({ commit }, { province_id, city_id }) {
      try {
        await axios.delete(`https://api.fusioncar.cl/api/global/deleteCityById/${province_id}/${city_id}`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          }
        );

        commit('DELETE_CITY', { province_id, city_id });
      } catch(error) {
        console.error('Error deleting city', error.response?.data || error.message);
        throw new Error(error.response.data.msg);
      }
    }
  },
  modules: {
  }
});

export default store;
