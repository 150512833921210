<template>
    <div class="fixed-top">
        <button v-if="isLoggedIn" @click="navbarToggler" class="toggle-navbar-btn rounded-circle" :class="{ toRight: !isNavbarVisible }">
            <i class="arrow-icon bi bi-arrow-right-circle fs-2" :class="{ rotateLeft: !isNavbarVisible, rotateRight: isNavbarVisible }"></i>
        </button>
    </div>
    
    <nav class="navbar navbar-expand-lg navbar-dark container-fluid fixed-top" :class="{ hidden: !isNavbarVisible }">
        <div class="container-fluid bg-carbon-fiber rounded-4 mx-2 mx-xl-5">
            <router-link to="/" class="navbar-brand border-0 ps-4" @click="closeMenu">
                <img id="logo" src="../../assets/img/globals/logo.png" alt="Logo">
            </router-link>
            <button class="navbar-toggler fs-6" type="button" @click="toggleMenu">
                <span class="navbar-toggler-icon"></span>
            </button>          
            <div class="collapse navbar-collapse rounded-bottom justify-content-center" id="navbarSupportedContent">
                <ul class="navbar-nav nav-list">
                    <li class="nav-item text-center mx-lg-4 px-2 px-lg-0">
                        <router-link to="/" class="nav-link btn bg-black-a5 rounded-pill border border-dark border-2" @click="closeMenu">Inicio</router-link>
                    </li>
                    <li class="nav-item text-center mx-lg-4 px-2 px-lg-0">
                        <router-link to="/volkswagen" class="nav-link btn bg-black-a5 rounded-pill border border-dark border-2" @click="closeMenu">Volkswagen</router-link>
                    </li>
                    <li class="nav-item text-center mx-lg-4 px-2 px-lg-0">
                        <router-link to="/products" class="nav-link bg-black-a5 rounded-pill border border-dark border-2" @click="closeMenu">Productos</router-link>
                    </li>
                    <li class="nav-item text-center mx-lg-4 px-2 px-lg-0 mb-3 mb-lg-0">
                        <router-link to="/contact" class="nav-link bg-black-a5 rounded-pill border border-dark border-2" @click="closeMenu">Contacto</router-link>
                    </li>
                    <li v-if="!isLoggedIn" class="navbar navbar-login d-none d-lg-block" :class="{ hidden: !isNavbarVisible}">
                        <router-link to="/login" class="border-0">
                            <button class="nav-login btn rounded-circle bg-black-a5" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Iniciar sesión">
                                <span class="bi bi-person-circle text-warning fs-2"></span>
                            </button>
                        </router-link>
                    </li>
                    <li v-if="isLoggedIn" class="navbar navbar-logout d-none d-lg-block" :class="{ hidden: !isNavbarVisible}">
                        <router-link to="/logout" class="border-0">
                            <button class="nav-logout btn rounded-circle bg-black-a5" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Cerrar sesión">
                                <span class="bi bi-power text-danger fs-2"></span>
                            </button>
                        </router-link>
                    </li>
                </ul>
                <div v-if="!isLoggedIn" class="mb-3 d-lg-none">
                    <router-link to="/login" class="nav-link border-0">
                        <button id="login" class="nav-login btn bg-yellow-a9 fw-bold rounded-pill" @click="closeMenu">Iniciar sesión</button>
                    </router-link>
                </div>
                <div v-if="isLoggedIn" @click="logout" class="mb-3 d-lg-none">
                    <router-link to="/logout" class="nav-link border-0">
                        <button id="logout" class="nav-logout btn bg-yellow-a9 fw-bold rounded-pill" @click="closeMenu">Cerrar sesión</button>
                    </router-link>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { Collapse, Tooltip } from 'bootstrap'
import { mapState, mapActions } from 'vuex'

export default {
    computed: {
        ...mapState(['isNavbarVisible', 'isLoggedIn'])
    },
    
    methods: {
        ...mapActions(['toggleNavbar', 'login']),

        navbarToggler() {
            this.$store.dispatch('toggleNavbar', !this.isNavbarVisible);
        },

        closeMenu() {
            const navbarCollapse = document.querySelector('.navbar-collapse');
            const bsCollapse = new Collapse(navbarCollapse, {
                toggle: false
            });
            bsCollapse.hide();
        },

        toggleMenu() {
            const navbarCollapse = document.querySelector('.navbar-collapse');
            const bsCollapse = new Collapse(navbarCollapse, {
                toggle: false
            });

            if (navbarCollapse.classList.contains('show')) {
                bsCollapse.hide();
            } else {
                bsCollapse.show();
            }
        }
    },

    watch: {
        isLoggedIn(newVal) {
            if (newVal) {
                this.$store.dispatch('toggleNavbar', false);
            } else {
                this.$store.dispatch('toggleNavbar', true);
            }
        }
    },

    mounted() {      
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));

        const token = localStorage.getItem('token');
        if (token) {
            this.$store.dispatch('login', true);
        } else {
            this.$store.dispatch('login', false);
            this.$store.dispatch('toggleNavbar', true)
        }
    }
}
</script>

<style lang="sass" scoped>
    @import '@/assets/sass/_variables.scss'
    @import '@/assets/sass/_mixins.scss'

    .toggle-navbar-btn
        position: absolute
        top: 25px
        left: 10px
        background: url('@/assets/img/globals/fiber-bg.png')
        color: $color-yellow
        padding: $fu3 $fu3 0
        border: none
        cursor: pointer
        transform: translateX(0)
        transition: transform 0.5s ease
        z-index: 6

        .arrow-icon
            display: inline-block
            transition: transform 0.3s ease
            transform-origin: center
            line-height: 0

        .rotateLeft
            transform: rotate(180deg)
            

        .rotateRight
            transform: rotate(0deg)

    .toRight
        transform: translateX(calc($vw50 + $vw30 + $vw5 + $vw1))

        @include media-breakpoint('lg')
            transform: translateX(calc($vw50 + $vw40 + $vw4))

        @include media-breakpoint('xl')
            transform: translateX(calc($vw50 + $vw40 + $vw5))

    .navbar
        transition: transform 0.5s ease, opacity 1s ease
        transform: translateX(0)
        opacity: 1
        z-index: 5

        &.hidden
            transform: translateX(calc($vp50 + $vp30))
            opacity: 0

        .bg-carbon-fiber
            background: url('@/assets/img/globals/fiber-bg.png')

        div

            .navbar-brand
                border: none

                #logo
                    width: $vu10

            .collapse
                .navbar-nav
                    .nav-item
                        padding-top: $fu10

                        .nav-link
                            width: $vp100
                            color: $color-light

                        @include media-breakpoint('lg')
                            width: calc($fu100 + $fu20)

                        .nav-link:hover
                            background-color: $color-black-a9

                    .navbar
                        a
                            .nav-login, .nav-logout
                                padding: $fu5
                                display: inline-block
                                line-height: 0

                            .nav-login:hover, .nav-logout:hover
                                background-color: $color-black-a9

                            .tooltip.bs-tooltip-left .tooltip-arrow::before
                                padding-top: 15px !important

                .router-link-active
                    color: $color-yellow !important
                    border: $fu2
                    border-style: solid
                    border-color: $color-yellow-a5 !important
                    font-weight: bold

                .router-link-active #login, .router-link-active #logout
                    color: $color-dark !important
</style>
