<template>
    <div class="sidebarComponent d-flex flex-column">
        <button @click="toggleSidebar" class="toggleSidebarBtn btn border-0">
            <i class="d-block bi bi-grid-3x3-gap-fill fs-3"></i>
        </button>
        <div class="sidebar row" :class=" { open: isSidebarVisible} ">
            <div class="sidebar-content align-self-center mt-5 pt-5">
                <ul>
                    <li class="d-flex justify-content-end">
                        <router-link :to="{ name: 'system' }" class="align-self-end text-start ps-2">
                            <span class="float-start">Panel</span><i class="bi bi-columns float-end fs-3 ps-3"></i>
                        </router-link>
                    </li>
                    <li class="d-flex justify-content-end">
                        <router-link :to="{ name: 'systemSells' }" class="align-self-end text-start ps-2">
                            <span class="float-start">Ventas</span><i class="bi bi-cart-check float-end fs-3 ps-3"></i>
                        </router-link>
                    </li>
                    <li class="d-flex justify-content-end">
                        <router-link :to="{ name: 'systemProducts' }" class="align-self-end text-start ps-2">
                            <span class="float-start">Productos</span><i class="bi bi-nut float-end fs-3 ps-3"></i>
                        </router-link>
                    </li>
                    <li class="d-flex justify-content-end">
                        <router-link :to="{ name: 'systemSuppliers' }" class="align-self-end text-start ps-2">
                            <span class="float-start">Proveedores</span><i class="bi bi-people float-end fs-3 ps-3"></i>
                        </router-link>
                    </li>
                    <li class="d-flex justify-content-end">
                        <router-link :to="{ name: 'systemITS'}" class="align-self-end text-start ps-2">
                            <span class="float-start">SII</span><i class="bi bi-bank float-end fs-3 ps-3"></i>
                        </router-link>
                    </li>
                    <li class="d-flex justify-content-end">
                        <router-link to="/dashboard/warehouses" class="align-self-end text-start ps-2">
                            <span class="float-start">Bodegas</span><i class="bi bi-shop float-end fs-3 ps-3"></i>
                        </router-link>
                    </li>
                    <li class="d-flex justify-content-end">
                        <router-link to="/stocktacking" class="align-self-end text-start ps-2">
                            <span class="float-start">Inventario</span><i class="bi bi-card-list float-end fs-3 ps-3"></i>
                        </router-link>
                    </li>
                    <li class="d-flex justify-content-end">
                        <router-link to="/system/profile" class="align-self-end text-start ps-2 mt-4">
                            <span class="float-start">Perfil</span><i class="bi bi-person-circle float-end fs-3 ps-3"></i>
                        </router-link>
                    </li>
                    <li class="d-flex justify-content-end">
                        <router-link to="/technicalSupport" class="align-self-end text-start ps-2">
                            <span class="float-start">Soporte técnico</span><i class="bi bi-tools float-end fs-3 ps-3"></i>
                        </router-link>
                    </li>
                    <li class="d-flex justify-content-end">
                        <router-link to="/settings" class="align-self-end text-start ps-2">
                            <span class="float-start">Configuración</span><i class="bi bi-gear-wide-connected float-end fs-3 ps-3"></i>
                        </router-link>
                    </li>
                    <li class="mt-3 pt-3">
                        <router-link to="/logout" class="border-0">
                            <button class="btn rounded-circle bg-black-a5" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Cerrar sesión">
                                <span class="bi bi-power text-danger fs-2"></span>
                            </button>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isSidebarVisible: false
            }
        },

        mounted() {
            const token = localStorage.getItem('token');
            if (token) {
                this.isSidebarVisible = false;
            }
        },

        methods: {
            toggleSidebar() {
                this.isSidebarVisible = !this.isSidebarVisible;
            }
        }
    }
</script>

<style lang="sass" scoped>
    @import '@/assets/sass/_variables.scss'
    @import '@/assets/sass/_mixins.scss'

    .toggleSidebarBtn
        position: fixed
        top: 80px
        left: 3px
        cursor: pointer
        z-index: 4

        i
            color: $color-grey
            transition: color 0.5s ease, transform 0.5s ease

        i:hover
            color: #fff
    .sidebar
        position: fixed
        top: 0px
        left: -185px
        width: 250px
        height: 100vh
        background-color: #000
        transition: all 0.5s ease
        z-index: 3

        @include media-breakpoint('lg')
            left: -180px

        .sidebar-content
            padding-right: 15px

            ul
                list-style: none
                padding: 0

                li
                    margin: 1em 0

                    a
                        width: 200px

                        i
                            line-height: 0

                    a, button
                        color: #fff
                        text-decoration: none
                        font-size: 1.2em
                        cursor: pointer

                    button
                        background-color: transparent
                        border: none
                        color: #fff
    .sidebar.open
        left: 0

    .router-link-exact-active i::before
        color: $color-yellow !important
</style>