<template>
    <div id="topCarousel" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-pause="false">
        <div class="carousel-indicators pb-3">
            <button type="button" data-bs-target="#topCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"><span></span></button>
            <button type="button" data-bs-target="#topCarousel" data-bs-slide-to="1" aria-label="Slide 2"><span></span></button>
            <button type="button" data-bs-target="#topCarousel" data-bs-slide-to="2" aria-label="Slide 3"><span></span></button>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="../../../assets/img/main/carousel/01.jpg" class="d-block w-100" alt="Slide 1">
                <div class="carousel-caption d-block bg-transparent rounded-5 mx-auto">
                    <div class="mt-0 mt-lg-3">
                        <span class="caption-title fw-semibold">Somos especialistas</span><br>
                        <span class="caption-title">en repuestos Volkswagen&reg;</span>
                        <hr class="border border-1 mx-auto opacity-100 rounded-pill">
                        <span class="caption-text">Con el mayor stock de la IV Región</span><br>
                        <router-link to="/volkswagen"><button class="btn bg-yellow-a9 fw-bold mt-4 px-5">Mas info...</button></router-link>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <img src="../../../assets/img/main/carousel/02.jpg" class="d-block w-100" alt="Slide 1">
                <div class="carousel-caption d-block bg-transparent rounded-5 mx-auto">
                    <div class="mt-0 mt-lg-3">
                        <span class="caption-title fw-semibold">Somos especialistas</span><br>
                        <span class="caption-title">en repuestos Volkswagen&reg;</span>
                        <hr class="border border-1 mx-auto opacity-100 rounded-pill">
                        <span class="caption-text">Con el mayor stock de la IV Región</span><br>
                        <router-link to="/volkswagen"><button class="btn bg-yellow-a9 fw-bold mt-4 px-5">Mas info...</button></router-link>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <img src="../../../assets/img/main/carousel/03.jpg" class="d-block w-100" alt="Slide 1">
                <div class="carousel-caption d-block bg-transparent rounded-5 mx-auto">
                    <div class="mt-0 mt-lg-3">
                        <span class="caption-title fw-semibold">Somos especialistas</span><br>
                        <span class="caption-title">en repuestos Volkswagen&reg;</span>
                        <hr class="border border-1 mx-auto opacity-100 rounded-pill">
                        <span class="caption-text">Con el mayor stock de la IV Región</span><br>
                        <router-link to="/volkswagen"><button class="btn bg-yellow-a9 fw-bold mt-4 px-5">Mas info...</button></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel } from 'bootstrap'

export default {
    mounted() {
        const headerCarousel = document.getElementById("topCarousel");
        const carouselIndicators = headerCarousel.querySelectorAll(".carousel-indicators button span");
        let intervalID;

        const carousel = new Carousel(headerCarousel);

        window.addEventListener('load', () => {
            fillCarouselIndicator(1);
        });

        headerCarousel.addEventListener("slide.bs.carousel", (e) => {
            let index = e.to;
            fillCarouselIndicator(++index);
        });

        const fillCarouselIndicator = (index) => {
            let i = 0;
            for (const carouselIndicator of carouselIndicators) {
                carouselIndicator.style.width = 0;
            };

            clearInterval(intervalID);
            carousel.pause();

            intervalID = setInterval(() => {
                i++;

                headerCarousel.querySelector(".carousel-indicators .active span").style.width = i + "%";

                if (i >= 100) {
                    carousel.next();
                }
            }, 50);
        };
    }
}
</script>

<style lang="sass" scoped>
    @import '@/assets/sass/_variables.scss'
    @import '@/assets/sass/_mixins.scss'

    .carousel
        z-index: 0

    .carousel-indicators [data-bs-target]
        position: relative
        width: $fu10
        height: $fu10
        border: none
        border-radius: $fu20
        margin: 0 1.5em

        span::before
            content: ''
            position: absolute
            top: 0
            left: 0
            width: 0
            height: $vp100

    .carousel-item
        transform: translate3d(0, 0, $fu100)

        img
            height: $vh40
            object-fit: cover

            @include media-breakpoint('sm')
                height: $vh50 + $vh20 + $vh5

            @include media-breakpoint('xl')
                height: $vh50 + $vh40

        .carousel-caption
            height: $vh30
            text-shadow: $fu2 $fu2 $fu3 $color-black

            @include media-breakpoint('md')
                height: $vh30

            @include media-breakpoint('lg')
                height: calc($vh30 + $vh5)

            @include media-breakpoint('xl')
                height: calc($vh40 + $vh5 + $vh3)

            @include media-breakpoint('xxl')
                height: calc($vh30 + $vh5)

            .caption-title
                font-size: $fu20

                @include media-breakpoint('xl')
                    font-size: $fu30

            hr
                width: calc($vw30 + $vw5)

                @include media-breakpoint('lg')
                    width: $vw30

                @include media-breakpoint('xl')
                    width: calc($vw10 + $vw5)

            .caption-text
                @include media-breakpoint('xl')
                    font-size: calc($fu20 + $fu3)

            .btn:hover
                background-color: $color-yellow
                color: $color-dark

    .carousel-fade
        .carousel-item
            opacity: 0
            transition-duration: .6s
            transition-property: opacity

        .carousel-item.active,
        .carousel-item-next.carousel-item-left,
        .carousel-item-prev.carousel-item-right 
            opacity: 1
 
        .active.carousel-item-left,
        .active.carousel-item-right
            opacity: 0
 
        .carousel-item-next,
        .carousel-item-prev,
        .carousel-item.active,
        .active.carousel-item-left,
        .active.carousel-item-prev
            transform: translateX(0)

</style>