import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import '@fontsource/nunito-sans'
import Toastify from 'toastify-js'
import 'toastify-js/src/toastify.css'

export const showToast = (message, options = {}) => {
    Toastify({
        text: message,
        duration: options.duration || 3000,
        close: options.close || false,
        gravity: options.gravity || 'top',
        position: options.position || 'right',
        ... options
    }).showToast();
};

createApp(App).use(store).use(router).mount('#app')
