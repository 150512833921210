import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import axios from 'axios'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFoundView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */'../views/LoginView.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */'../views/LogoutView.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "signup" */'../views/SignupView.vue')
  },
  {
    path: '/system/',
    name: 'system',
    component: () => import(/* webpackChunkName: "system" */'../views/SystemView.vue'),
    children: [
      {
        path: 'sells',
        name: 'systemSells',
        component: () => import(/* webpackChunkName: "systemSells" */'../views/system/SellsView.vue')
      },
      {
        path: 'products',
        name: 'systemProducts',
        component: () => import(/* webpackChunkName: "systemProducts" */'../views/system/SystemProductsView.vue')
      },
      {
        path: 'spareParts',
        name: 'systemSpareParts',
        component: () => import(/* webpackChunkName: "systemSpareParts" */'../components/systemProducts/SparePartsComponent.vue')
      },
      {
        path: 'lightning',
        name: 'systemLightning',
        component: () => import(/* webpackChunkName: "systemLightning" */'../components/systemProducts/LightningComponent.vue')
      },
      {
        path: 'security',
        name: 'systemSecurity',
        component: () => import(/* webpackChunkName: "systemSecurity" */'../components/systemProducts/SecurityComponent.vue')
      },
      {
        path: 'lubricants',
        name: 'systemLubricants',
        component: () => import(/* webpackChunkName: "systemLubricants" */'../components/systemProducts/LubricantsComponent.vue')
      },
      {
        path: 'accesories',
        name: 'systemAccesories',
        component: () => import(/* webpackChunkName: "systemAccesories" */'../components/systemProducts/AccesoriesComponent.vue')
      },
      {
        path: 'suppliers',
        name: 'systemSuppliers',
        component: () => import(/* webpackChunkName: "systemSuppliers" */'../views/system/SuppliersView.vue')
      },
      {
        path: 'its',
        name: 'systemITS',
        component: () => import(/* webpackChunkName: "systemITS" */'../views/system/ITSView.vue')
      },
      {
        path: 'profile/',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */'../views/system/ProfileView.vue'),
        children: [
          {
            path: 'addresses',
            name: 'addresses',
            component: () => import(/* webpackChunkName: "addresses" */'../components/dashboard/addressesComponent.vue')
          }
        ]
      },
      {
        path: '/settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "settings" */'../views/system/SettingsView.vue')
      },
      {
        path: 'warehouses',
        name: 'warehouses',
        component: () => import(/* webpackChunkName: "warehouses" */'../views/system/WarehousesView.vue')
      },
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/volkswagen',
    name: 'volkswagen',
    component: () => import(/* webpackChunkName: "volkswagen" */'../views/BrandView.vue')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "products" */'../views/products/ProductsView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Global guard that checks if the route requires authentication
router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token');
  const tokenExpiration = localStorage.getItem('tokenExpiration');
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if(requiresAuth && !token) {
    next({ name: 'login'});
  } else if(requiresAuth && token) {
    if (tokenExpiration && Date.now() >= tokenExpiration) {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiration');
      next({ name: 'logout'});
    } else {
      try {
        const response = await axios.post('https://api.fusioncar.cl/api/global/verify', {}, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
  
        if(response.status === 200) {
          next();
        } else {
          localStorage.removeItem('token');
          next({ name: 'login'});
        };
      } catch(error) {
        console.error('Error verifying token', error);
        localStorage.removeItem('token');
        next({ name: 'login'});
      };
    };
  } else {
    next();
  };
});

export default router
